import { SiteConfig } from "@/types"

export const siteConfig: SiteConfig = {
  name: "Botbyte AI",
  description:
    `Transform your website into a 24/7 customer engagement powerhouse with BotByte AI – where instant support meets seamless interaction!`
     + "<br>" + `<a href="https://botbyte.in/">Botbyte AI</a>`
    + "<br>" + `< href="https://botbyte.in/featured">We have been featured on</a>` + "<br>" + `<a href="https://botbyte.in/docs">Documentation</a>` + "<br>" + `<a href="https://botbyte.in/careers">Careers</a>` + "",
  url: "https://botbyte.in/",
  ogImage: "https://botbyte.in/laptopbot.png",
  links: {
    twitter: "https://twitter.com/sarcastic_sb",
    github: "https://github.com/sanketbagad",
    productHunt: "https://www.producthunt.com/posts/botbyte-ai",
    buyMeACoffee: "https://www.buymeacoffee.com/sanketbagad",
    hashnode: "https://sanketbagad.hashnode.dev/",
    devhunt: "https://devhunt.org/tool/bytebot-ai",
    resourceFyi: "https://resource.fyi/products/botbyte-ai",
  },
}
